var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-col',{staticClass:"h-100 overflow-hidden"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticStyle:{"margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")]),_c('a-button',{staticStyle:{"margin-left":"auto"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'menu-create' })}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")],1)],1),(_vm.loading)?_c('spinner'):_c('a-row',{staticClass:"list-container menu-list__container thin-scroll"},[_c('Tree',{attrs:{"value":_vm.sortChildMenuList(_vm.list)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var tree = ref.tree;
var path = ref.path;
return (!node.isDragPlaceHolder)?_c('a-row',{attrs:{"type":"flex","align":"middle"}},[(node.children && node.children.length)?_c('a-icon',{staticStyle:{"padding-right":"5px"},attrs:{"type":node.$folded ? 'plus' : 'minus'},on:{"click":function($event){return tree.toggleFold(node, path)}}}):_vm._e(),_c('router-link',{staticClass:"td-post-item",attrs:{"to":{
            name: 'menu-detail',
            params: {
              id: node.id,
            },
          },"tag":"span"}},[(node.on_header)?_c('span',{staticClass:"mr-1"},[_c('a-icon',{attrs:{"type":"pushpin"}})],1):_vm._e(),_c('span',[_vm._v(_vm._s(node.name || "Нет название")+" ")])]),_c('a-row',{staticClass:"btns",attrs:{"type":"flex","align":"middle"}},[(!node.parent)?_c('div',[_c('a-switch',{staticStyle:{"margin-right":"15px"},on:{"change":function($event){return _vm.checkMain(node, $event)}},model:{value:(node.on_header),callback:function ($$v) {_vm.$set(node, "on_header", $$v)},expression:"node.on_header"}}),_c('a-tag',{staticStyle:{"min-width":"110px","text-align":"center"},attrs:{"color":node.on_header ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(node.on_header ? _vm.$t('onMain') : _vm.$t('notOnMain'))+" ")])],1):_vm._e(),_c('a-switch',{staticStyle:{"margin-right":"15px"},on:{"change":function($event){return _vm.checkActive(node, $event)}},model:{value:(node.status),callback:function ($$v) {_vm.$set(node, "status", $$v)},expression:"node.status"}}),_c('a-tag',{staticStyle:{"min-width":"110px","text-align":"center"},attrs:{"color":node.status ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(node.status ? _vm.$t('Published') : _vm.$t('NotPublished'))+" ")]),_c('a-button',{staticClass:"edit-btn",staticStyle:{"height":"40px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'menu-detail', params: { id: node.id } })}}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"edit"}})],1),_c('a-popconfirm',{attrs:{"title":_vm.$t('APopconfirm'),"ok-text":_vm.$t('Yes'),"cancel-text":_vm.$t('No')},on:{"confirm":function($event){return _vm.removeItem(node)}}},[_c('a-button',{staticStyle:{"height":"40px"},attrs:{"type":"primary","danger":""}},[_c('a-icon',{staticClass:"action-btns",attrs:{"type":"delete"}})],1)],1)],1)],1):_vm._e()}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }