<template>
  <a-col class="h-100 overflow-hidden">
    <a-row class="content-title" type="flex">
      <h2 style="margin-bottom: 0">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'menu-create' })"
      >
        <a-icon type="plus" />
        {{ $t("Add") }}
      </a-button>
    </a-row>

    <spinner v-if="loading" />

    <a-row v-else class="list-container menu-list__container thin-scroll">
      <Tree :value="sortChildMenuList(list)">
        <a-row
          v-if="!node.isDragPlaceHolder"
          slot-scope="{ node, tree, path }"
          type="flex"
          align="middle"
        >
          <a-icon
            v-if="node.children && node.children.length"
            style="padding-right: 5px"
            :type="node.$folded ? 'plus' : 'minus'"
            @click="tree.toggleFold(node, path)"
          />
          <!-- <a-input-number v-model="node.position" style="width: 50px; margin-right: 10px"
            @change="updatePosition(node, $event)" /> -->
          <router-link
            :to="{
              name: 'menu-detail',
              params: {
                id: node.id,
              },
            }"
            class="td-post-item"
            tag="span"
          >
            <span v-if="node.on_header" class="mr-1">
              <a-icon type="pushpin" />
            </span>
            <span>{{ node.name || "Нет название" }} </span>
          </router-link>
          <a-row class="btns" type="flex" align="middle">
            <div v-if="!node.parent">
              <a-switch
                v-model="node.on_header"
                style="margin-right: 15px"
                @change="checkMain(node, $event)"
              />
              <a-tag
                style="min-width: 110px; text-align: center"
                :color="node.on_header ? 'geekblue' : 'volcano'"
              >
                {{ node.on_header ? $t('onMain') : $t('notOnMain') }}
              </a-tag>
            </div>
            <a-switch
              v-model="node.status"
              style="margin-right: 15px"
              @change="checkActive(node, $event)"
            />
            <a-tag
              style="min-width: 110px; text-align: center"
              :color="node.status ? 'geekblue' : 'volcano'"
            >
              {{ node.status ? $t('Published') : $t('NotPublished') }}
            </a-tag>
            <a-button
              style="height: 40px"
              type="primary"
              class="edit-btn"
              @click="
                $router.push({ name: 'menu-detail', params: { id: node.id } })
              "
            >
              <!-- {{ $t("Edit") }} -->
              <a-icon type="edit" class="action-btns" />
            </a-button>

            <a-popconfirm
              :title="$t('APopconfirm')"
              :ok-text="$t('Yes')"
              :cancel-text="$t('No')"
              @confirm="removeItem(node)"
            >
              <a-button style="height: 40px" type="primary" danger>
                <!-- {{ $t("Delete") }} -->
                <a-icon type="delete" class="action-btns" />
              </a-button>
            </a-popconfirm>
          </a-row>
        </a-row>
      </Tree>
    </a-row>
  </a-col>
</template>

<script>
import "he-tree-vue/dist/he-tree-vue.css";
import { Tree, Fold } from "he-tree-vue";

export default {
  components: {
    Tree: Tree.mixPlugins([Fold]),
    // breadthFirstSearch
  },
  data() {
    return {
      loading: false,
      list: [],
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          scopedSlots: { customRender: "lang_title" },
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          scopedSlots: { customRender: "operation" },
          width: 300,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.fetchData();
  },
  methods: {
    parseTitles(list) {
      return (
        list &&
        list.map((item) => ({
          ...item,
          $folded: true,
          title: {
            ...this.langKeys,
            ...(item.title && JSON.parse(item.title)),
          },
          children: (item.children && this.parseTitles(item.children)) || [],
        }))
      );
    },
    updatePosition(node, position) {
      this.loading = true;
      this.$api
        .put(`/admin/menu/${node.id}`, {
          position,
        })
        .then(() => {
          this.loading = false;
          this.fetchData();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    async checkActive(item, event) {
      await this.$api.put(`/admin/menu/${item.id}`, {
        status: event,
      });
    },
    async checkMain(item, event) {
      await this.$api.put(`/admin/menu/${item.id}`, {
        on_header: event,
      });
    },
    async fetchData() {
      this.loading = true;
      try {
        const { data } = await this.$store.dispatch("menu/fetch", {
          parent: true,
        });
        data && this.$set(this, "list", data);
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async removeItem(arg) {
      try {
        const res = await this.$store.dispatch("menu/removeItemAction", arg.id);
        console.log(res);
        if (res) {
          this.$message.success(this.$t('SucDelete'));
          this.fetchData();
        } else {
          this.$message.error(this.$t('NotDelete'));
          throw this.$t('NotDelete2');
        }
        // console.log(res)
      } catch (err) {
        console.log(err);
      }
    },

    sortChildMenuList(list) {
      return (
        list &&
        list
          .slice()
          .map((item) => ({
            ...item,
            $folded: true,
            children:
              (item.children && this.sortChildMenuList(item.children)) || [],
          }))
          .slice()
          .sort((a, b) => a.position - b.position)
      );
    },
  },
};
</script>

<style lang="scss">
.tree-node-inner {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

// .draggable-placeholder {
// }

.draggable-placeholder-inner {
  border: 1px dashed #0088f8;
  box-sizing: border-box;
  background: rgba(0, 136, 249, 0.09);
  color: #0088f9;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
}

.tree3 .tree-node-inner {
  border: none;
  padding: 0px;
}

.tree3 .tree-node-inner-back:hover {
  background: #ddd;
}

.tree4 .tree-node-inner {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  background: #ccc;
}

.tree4 .draggable-placeholder-inner {
  background-color: orangered;
}

.he-tree .tree-node {
  padding: 8px 15px;
}
</style>
